/* OVERRIDES */
.navbar-toggler { border-radius: 1rem 1rem 1rem 0; }

.tabler .custom-switch-input ~ .custom-switch-indicator {
  border-color: #f04131;
  background: #f04131;
}

.tabler .custom-switch-input:checked ~ .custom-switch-indicator {
  border-color: #22bd75;
  background: #22bd75;
}

.tabler .inverted-switch .custom-switch-input:focus ~ .custom-switch-indicator,
.tabler .inverted-switch .custom-switch-input ~ .custom-switch-indicator {
  border-color: #22bd75;
  background: #22bd75;
}

.tabler .inverted-switch .custom-switch-input:checked ~ .custom-switch-indicator {
  border-color: #f04131;
  background: #f04131;
}

.tabler .inverted-switch .custom-switch-indicator:before { left: calc(1rem + 1px); }

.tabler .inverted-switch .custom-switch-input:checked ~ .custom-switch-indicator:before { left: 1px; }

header .dropdown .nav-link { cursor: pointer; }

/* FORMS */
input[type=tel] { max-width: 290px; }

/* React Select */
.cs-select-container .react-select__control {
  border: 1px solid #ced4da;
}

.cs-select-container .react-select__control:hover {
  border-color: #ced4da;
}

.cs-select-container .react-select__control:focus,
.cs-select-container .react-select__control--is-focused {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.cs-select-container .react-select__menu {
  border: 1px solid #ced4da;
  box-shadow: none;
  margin-top: 4px;
  margin-bottom: 4px;
}

.cs-select-container .react-select__menu-list {
  padding: 0;
}

/* NOTIFICATIONS */
.notification-item .notification-close:after { font-size: 18px; }

/* ALERT CONFIRM */
.mini-alert-confirm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.mini-alert-confirm .box {
  width: calc(50% - 10px);
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0.7);
  width: calc(100% - 20px);
  max-width: 400px;
  min-height: 100px;
  position: fixed;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
  padding: 15px;
  text-align: center;
  transition: 0.3s;
  max-height: 460px;
  overflow-y: auto;
  border-radius: .25rem;
  background-color: #fff;
  color: #333;
}

.mini-alert-confirm .box .btn {
  margin: 0 4px;
  width: 40%;
}

.mini-alert-confirm .box .area {
  margin-bottom: 15px;
  font-size: 16px;
  white-space: pre-line;
}

.mini-alert-confirm .box .area small {
  text-transform: uppercase;
  opacity: 0.6;
  font-size: 18px;
}

.mini-alert-confirm .box .area b { font-size: 26px; }

.mini-alert-confirm.alert-enter,
.mini-alert-confirm.alert-enter-active,
.mini-alert-confirm.alert-enter-done {
  opacity: 1;
  visibility: visible;
  background-color: rgba(0,0,0,.3);
}

.mini-alert-confirm.alert-enter-active .box, .mini-alert-confirm.alert-enter-done .box {
  transform: translateY(-50%) translateX(-50%) scale(1);
}
