.app-public .navbar-toggler-icon { display: none; }

/* OVERRIDES */
.tabler { background-color: #f5f7fb; }

.tabler .page > .flex-fill { max-width: 100%; }

.tabler .footer {
  background: #fff;
  border-top: 1px solid rgba(0, 40, 100, 0.12);
  font-size: 0.875rem;
  padding: 1.25rem 0;
  color: #9aa0ac;
  position: relative;
  margin-bottom: 0;
  z-index: 1;
}

/* RESPONSIVE TABLE */
@media (max-width: 991px) {
  .tabler table thead { display: none; }

  .tabler table tbody,
  .tabler .card-table tr { display: block; }

  .tabler .card-table tr:not(:first-child) { border-top: 1px solid rgba(0, 0, 0, 0.09); }

  .tabler .card-table tr td,
  .tabler .card-table tr td:first-child {
    display: flex;
    border-top: 0;
    padding: 0 .75rem .75rem 1.25rem;
  }

  .tabler .card-table tr td:first-child { padding: .75rem .75rem .75rem 1.25rem; }

  .tabler table td:empty { display: none; }

  .tabler table td:before {
    content: attr(data-label);
    flex: 0 0 auto;
    width: 33%;
    padding-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #999;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    align-self: center;
  }
}

@media (min-width: 992px) {
  .responsive-table { white-space: nowrap; }
}

.tabler .icon-as-data .icon {
  font-size: 1.1rem;
  line-height: 1.5rem;
}


/* AUDIO PLAYER */
.small-player .plyr {
  max-width: 120px;
  min-width: 120px;
}

.tabler table .small-player .plyr--audio .plyr__controls {
  justify-content: space-between;
  padding: 5px 0;
}

.tabler table .small-player .plyr--audio .plyr__controls > .plyr__control:first-child { margin-right: 0; }

/* ACCESS CODE */
.access-code-box {
  position: absolute;
  top: 26px;
  border: 1px solid #ced4da;
  padding: .25rem .5rem;
  margin-left: .5rem;
  line-height: 1.25rem;
  border-radius: .25rem;
}

.form-label i.icon {
  color: #777;
  font-size: 1.2em;
  margin-right: .25rem;
}
